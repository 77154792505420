const STRINGS = {
    app_name: "app_name",
    get_route_code: "get_route_code",
    enter_route_code: "enter_route_code",
    enter_code_here: "enter_code_here",
    confirm: "confirm",
    select_pick_task: "select_pick_task",
    select_stage_task: "select_stage_task",
    get_pick_list: "get_pick_list",
    get_picklist_description: "get_picklist_description",
    enter_route_code_description: "enter_route_code_description",
    directed_picking_complete: "directed_picking_complete",
    pick_like_pro: "pick_like_pro",
    swipe_to_finish: "swipe_to_finish",
    stage_more: "stage_more",
    end_staging: "end_staging",
    pick_more: "pick_more",
    end_picking: "end_picking",
    associate_already_assigned: "associate_already_assigned",
    no_assignable_route_found: "no_assignable_route_found",
    no_optimal_route_found: "no_optimal_route_found",
    route_invalid: "route_invalid",
    invalid_cart_id: "invalid_cart_id",
    invalid_cart: "invalid_cart",
    route_not_assigned_to_associate: "route_not_assigned_to_associate",
    cart_already_added_to_route: "cart_already_added_to_route",
    cart_belong_to_route: "cart_belong_to_route",
    staging_area_not_valid: "staging_area_not_valid",
    cart_already_staged: "cart_already_staged",
    item_not_belong_to_picklist: "item_not_belong_to_picklist",
    cannot_move_item_to_cart: "cannot_move_item_to_cart",
    invalid_route_code: "invalid_route_code",
    picklist_assigned: "picklist_assigned",
    wrong_item_scan: "wrong_item_scan",
    incorrect_problem_solve_bin: "incorrect_problem_solve_bin",
    route_picked: "route_picked",
    picklist_departed: "picklist_departed",
    picklist_not_assigned: "picklist_not_assigned",
    pre_staging_area_full: "pre_staging_area_full",
    route_status: "route_status",
    cart_open: "cart_open",
    stage_location: "stage_location",
    assigned_route: "assigned_route",
    aisle: "aisle",
    back_button_pressed: "back_button_pressed",
    btn_continue_picking: "btn_continue_picking",
    btn_end_picking_and_exist: "btn_end_picking_and_exist",
    btn_acknowledge_close_sector: "btn_acknowledge_close_sector",
    route_has_issue: "route_has_issue",
    picking_can_not_be_complete: "picking_can_not_be_complete",
    picklist_no_longer_assigned: "picklist_no_longer_assigned",
    incorrect_staging_location: "incorrect_staging_location",
    btn_continue: "btn_continue",
    btn_cancel: "btn_cancel",
    cart: "cart",
    location: "location",
    scan_stage_location: "scan_stage_location",
    missing_cart: "missing_cart",
    missing: "missing",
    missing_cart_confirmation: "missing_cart_confirmation",
    back_button_notification_message: "back_button_notification_message",
    route_partially_staged: "route_partially_staged",
    route_partially_staged_desc: "route_partially_staged_desc",
    route_not_staged: "route_not_staged",
    route_not_staged_desc: "route_not_staged_desc",
    no_route_assigned: "no_route_assigned",
    no_route_available: "no_route_available",
    no_route_assigned_desc: "no_route_assigned_desc",
    back_cancel_message: "back_cancel_message",
    back_confirm_message: "back_confirm_message",
    picking_aisles: "picking_aisles",
    directed_staging_location: "directed_staging_location",
    item_count: "item_count",
    cart_count: "cart_count",
    pick_overview: "pick_overview",
    incorrect_stage_scan: "incorrect_stage_scan",
    picklist_item_unavailable: "picklist_item_unavailable",
    package_cancelled: "package_cancelled",
    picking_completion: "picking_completion",
    pick_internal_error: "pick_internal_error",
    connection_timeout: "connection_timeout",
    total_items_bags_ov_packages: "total_items_bags_ov_packages",
    invalid_cart_status: "invalid_cart_status",
    psa_not_configured: "psa_not_configured",
    cart_staged_location: "cart_staged_location",
    stage_location_updated: "stage_location_updated",
    stage_container_label: "stage_container_label",
    stage_container_title: "stage_container_title",
    stage_container_desc: "stage_container_desc",
    stage_cart_open: "stage_cart_open",
    trailer_not_found: "trailer_not_found",
    stage_get_containers_stage: "stage_get_containers_stage",
    stage_get_containers_stage_desc: "stage_get_containers_stage_desc",
    stage_cart_id: "stage_cart_id",
    operation_pick_stage: "operation_pick_stage",
    operation_directed_pick: "operation_directed_pick",
    operation_batching: "operation_batching",
    picking: "picking",
    setup: "setup",
    pre_pick_stage_header_message: "pre_pick_stage_header_message",
    btn_previous_steps: "btn_previous_steps",
    all_set_task: "all_set_task",
    cart_sign_board: "cart_sign_board",
    btn_restart_setup: "btn_restart_setup",
    btn_start_picking: "btn_start_picking",
    loading: "loading",
    no_sector: "no_sector",
    picking_sector: "picking_sector",
    select_sector: "select_sector",
    picking_readiness: "picking_readiness",
    sector: "sector",
    btn_sector_picking_complete: "btn_sector_picking_complete",
    package_id: "package_id",
    scan_package_label: "scan_package_label",
    scan_bag_label: "scan_bag_label",
    pick_new_sector: "pick_new_sector",
    free_picking_complete: "free_picking_complete",
    package_by_user: "package_by_user",
    remaining_package: "remaining_package",
    incomplete_routes: "incomplete_routes",
    route_not_picked_fully: "route_not_picked_fully",
    route_id: "route_id",
    packages_count: "packages_count",
    choose_sector: "choose_sector",
    route_code: "route_code",
    invalid_package_id: "invalid_package_id",
    check_route_status: "check_route_status",
    assigned_sector: "assigned_sector",
    picking_in_sector: "picking_in_sector",
    incorrect_scan: "incorrect_scan",
    time_lapsed: "time_lapsed",
    route_title_batch: "route_title_batch",
    free_batching: "free_batching",
    batching: "batching",
    loading_plan: "loading_plan",
    cart_already_added: "cart_already_added",
    capacity_exits_in_old_cart: "capacity_exits_in_old_cart",
    cart_already_in_use: "cart_already_in_use",
    cart_validation_failed: "cart_validation_failed",
    container_does_not_present: "container_does_not_present",
    no_plan_assigned: "no_plan_assigned",
    order_cancel: "order_cancel",
    package_does_not_exist: "package_does_not_exist",
    plan_deos_not_exist: "plan_deos_not_exist",
    no_route: "no_route",
    batching_completed: "batching_completed",
    cart_for_batching: "cart_for_batching",
    cart_readiness: "cart_readiness",
    btn_start: "btn_start",
    btn_back: "btn_back",
    routes: "routes",
    package_already_picked: "package_already_picked",
    pick_route_free_picking: "pick_route_free_picking",
    tap_to_pick: "tap_to_pick",
    free_picking: "free_picking",
    pick_particular_route: "pick_particular_route",
    route_picking_complete: "route_picking_complete",
    stop_batching: "stop_batching",
    batching_complete: "batching_complete",
    finish_batching: "finish_batching",
    batched_by_user: "batched_by_user",
    rps_invalid_input: "rps_invalid_input",
    rps_invalid_route_code: "rps_invalid_route_code",
    rps_associate_already_assigned: "rps_associate_already_assigned",
    rps_route_already_assigned: "rps_route_already_assigned",
    rps_no_assignable_routes: "rps_no_assignable_routes",
    rps_retry_operation: "rps_retry_operation",
    pick_to_route: "pick_to_route",
    pick_to_route_description: "pick_to_route_description",
    get_cart_for_picking: "get_cart_for_picking",
    scan_new_cart: "scan_new_cart",
    scan_cart: "scan_cart",
    picking_in_aisles: "picking_in_aisles",
    scan_bag: "scan_bag",
    pick_scan_package: "pick_scan_package",
    overflow_rack: "overflow_rack",
    cart_full: "cart_full",
    shelf: "shelf",
    bag_id: "bag_id",
    place_bag_in_cart: "place_bag_in_cart",
    place_package_in_cart: "place_package_in_cart",
    move_cart: "move_cart",
    cart_staged: "cart_staged",
    picking_complete: "picking_complete",
    rescan_package: "rescan_package",
    rescan_bag: "rescan_bag",
    move_item_instruction: "move_item_instruction",
    place_package_in_location: "place_package_in_location",
    place_bag_in_location: "place_bag_in_location",
    scan_location: "scan_location",
    confirm_action: "confirm_action",
    package_skipped: "package_skipped",
    bag_skipped: "bag_skipped",
    move_package: "move_package",
    move_bag: "move_bag",
    scan_bin: "scan_bin",
    damaged: "damaged",
    picking_problem: "picking_problem",
    missing_item: "missing_item",
    damaged_item: "damaged_item",
    bag_picked: "bag_picked",
    package_picked: "package_picked",
    cart_staged_message: "cart_staged_message",
    package_moved_ps: "package_moved_ps",
    bag_moved_ps: "bag_moved_ps",
    item_missing: "item_missing",
    rescan_item: "rescan_item",
    move_item_back: "move_item_back",
    confirm_item_location: "confirm_item_location",
    item_id: "item_id",
    item_move_back: "item_move_back",
    place_item_in_location: "place_item_in_location",
    incorrect_item: "incorrect_item",
    package_skipped_notification: "package_skipped_notification",
    bag_skipped_notification: "bag_skipped_notification",
    use_this_cart: "use_this_cart",
    btn_scan_new_cart: "btn_scan_new_cart",
    wrong_location_scan: "wrong_location_scan",
    wrong_cart_scan: "wrong_cart_scan",
    find_correct_cart: "find_correct_cart",
    package_removed_from_picklist: "package_removed_from_picklist",
    bag_removed_from_picklist: "bag_removed_from_picklist",
    find_cart_to_pick: "find_cart_to_pick",
    cannot_find_cart: "cannot_find_cart",
    last_chance_to_pick_package: "last_chance_to_pick_package",
    last_chance_to_pick_bag: "last_chance_to_pick_bag",
    wrong_barcode_scan: "wrong_barcode_scan",
    cart_some_issue: "cart_some_issue",
    pick_by_sort_zone: "pick_by_sort_zone",
    complete: "complete",
    remaining: "remaining",
    aisle_name: "aisle_name",
    scan_damaged_item: "scan_damaged_item",
    directed_package_picked: "directed_package_picked",
    directed_bag_picked: "directed_bag_picked",
    btn_use_this_cart: "btn_use_this_cart",
    aisle_rack_name: "aisle_rack_name",
    bag: "bag",
    next_bag: "next_bag",
    unable_to_scan: "unable_to_scan",
    keyboard: "keyboard",
    type_id_from_keyboard: "type_id_from_keyboard",
    enter_package_id: "enter_package_id",
    enter_bag_id: "enter_bag_id",
    last_pick_location: "last_pick_location",
    no_item_picked: "no_item_picked",
    pachinko_package_scan_error: "pachinko_package_scan_error",
    package_moved_to_rack: "package_moved_to_rack",
    items_completion_summary: "items_completion_summary",
    single_items_completion_summary: "single_items_completion_summary",
    picking_bag_picked: "picking_bag_picked",
    picking_bags_picked: "picking_bags_picked",
    picking_package_picked: "picking_package_picked",
    picking_packages_picked: "picking_packages_picked",
    picking_bag_stage_summary: "picking_bag_stage_summary",
    picking_package_stage_summary: "picking_package_stage_summary",
    picking_missing_bags: "picking_missing_bags",
    picking_missing_bag: "picking_missing_bag",
    picking_missing_packages: "picking_missing_packages",
    picking_missing_package: "picking_missing_package",
    scan_pre_stage_area: "scan_pre_stage_area",
    move_cart_to_pre_stage: "move_cart_to_pre_stage",
    pre_staging_area_not_valid: "pre_staging_area_not_valid",
    mixed_cart:"mixed_cart",
    mixed_cart_large_packages:"mixed_cart_large_packages",
    stack_bags_two_columns:"stack_bags_two_columns",
    got_it:"got_it"
}

export default STRINGS;
