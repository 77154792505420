export const APP_DATA_KEYS = {
    ACTIVE_CART: "ACTIVE_CART",
    CART_HAS_ITEM: "CART_HAS_ITEM",
    PICKLIST: "PICKLIST",
    WORKFLOW: "WORKFLOW",
    APP_MOCK_NETWORK: "APP_MOCK_NETWORK",
    ACCESS_TOKEN: "ACCESS_TOKEN",
    APP_REGION: "APP_REGION",
    APP_ENV: "APP_ENV",
    // APP VERSION
    APP_VERSION: "APP_VERSION",
    CART_STAGED_FOR_PICKLIST: "CART_STAGED_FOR_PICKLIST"
};

class AppData {

    save(key, value) {
        if (value === null || value === undefined) {
            sessionStorage.removeItem(key);
        } else {
            sessionStorage.setItem(key, JSON.stringify(value));
        }
    }

    remove(key) {
        sessionStorage.removeItem(key);
    }

    get(key) {
        const value = sessionStorage.getItem(key);
        try {
            return JSON.parse(value);
        } catch (e) {
            console.log(`error while parsing data for ${key} with value ${value}`);
            return null;
        }
    }

    clearPickingData() {
        AppDataStore.remove(APP_DATA_KEYS.PICKLIST);
        AppDataStore.remove(APP_DATA_KEYS.WORKFLOW);
        AppDataStore.remove(APP_DATA_KEYS.ACTIVE_CART);
    }

    saveActiveCart(cart, isNewCart) {
        this.save(APP_DATA_KEYS.ACTIVE_CART, cart);
        if(cart && isNewCart) {
            this.setCartHasItem(cart.id, false);
        }
    }

    getActiveCart() {
        return this.get(APP_DATA_KEYS.ACTIVE_CART);
    }

    savePicklist(picklist) {
        this.save(APP_DATA_KEYS.PICKLIST, picklist);
    }

    getPicklist() {
        return this.get(APP_DATA_KEYS.PICKLIST);
    }

    saveWorkflow(workflow) {
        this.save(APP_DATA_KEYS.WORKFLOW, workflow);
    }

    getWorkflow() {
        return this.get(APP_DATA_KEYS.WORKFLOW);
    }

    // we are never deleting the entry but depend on the session to expire or we replace the value
    // we are also handling the scenario where some items are picked and some are missed but they are not in a cart
    setCartHasItem(cartId, value) {
        AppDataStore.save(`${APP_DATA_KEYS.CART_HAS_ITEM}-${cartId}`, value);
    }

    doesCartHasItem(cartId) {
        return !!AppDataStore.get(`${APP_DATA_KEYS.CART_HAS_ITEM}-${cartId}`);
    }

    getIsCartStagedForPicklist() {
       return this.get(APP_DATA_KEYS.CART_STAGED_FOR_PICKLIST);
    }

    setIsCartStagedForPicklist(state) {
        this.save(APP_DATA_KEYS.CART_STAGED_FOR_PICKLIST, state);
    }
}

const AppDataStore = new AppData();

export default AppDataStore;