import React, {useEffect, useState} from 'react';
import {Route, Switch} from 'react-router-dom';
import Routing, {ROUTE_PATH} from "./routing";
import AppLayout from "@amzn/meridian/app-layout";
import Column from "@amzn/meridian/column";
import AppContextProvider from "./context/app-context";
import withDevSupport from "./dev-support";
import {IntlProvider} from "react-intl";
import LocaleManagerInstance from "./locale/locale-manager";
import {FeatureManager, MeshClient} from "@amzn/dolphin-web-framework";
import AppDataStore, {APP_DATA_KEYS} from "./app-data";
import {Loader} from "@amzn/dolphin-uiweb-framework";

const AppTopNavigation = React.lazy(() => import('./components/app-top-navigation'));

export default function App() {
    const locale = LocaleManagerInstance.getLocale();
    const messages = LocaleManagerInstance.getMessages();
    const [loadFeatures, setLoadFeatures] = useState(true);

    useEffect( () => {
        async function initialize() {
            await MeshClient.connectWebSocket();
            FeatureManager.getFeatures(AppDataStore.get(APP_DATA_KEYS.APP_ENV),
                AppDataStore.get(APP_DATA_KEYS.APP_REGION)).then(()=>{
                setLoadFeatures(false);
            });
        }
        initialize();
        return () => {
            MeshClient.closeWebSocketConnection();
        };
    },[]);

    return <IntlProvider locale={locale} messages={messages}>
        <AppContextProvider>
            {loadFeatures ? <Loader/> : null}
            <AppLayout>
                {withDevSupport(
                    <Column id={"container"} width="100%" height="100%" spacing="none">
                        <React.Suspense fallback={null}>
                            {/* if route='/' exact true is null and otherwise we return the top nav*/}
                            <Switch>
                                <Route exact={true} path={ROUTE_PATH.INDEX} component={() => null}/>
                                <Route component={AppTopNavigation}/>
                            </Switch>
                        </React.Suspense>
                        <Column className="container" height="100%" heights="fill">
                            <Routing/>
                        </Column>
                    </Column>
                )}
            </AppLayout>
        </AppContextProvider>
    </IntlProvider>;
}
