import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import AppError from "./components/app-error";
import {Loader} from "@amzn/dolphin-uiweb-framework";
import AppLauncher from "./components/app-launcher";
import {PUMAMetricHelper} from "@amzn/dolphin-web-framework";

const DirectedPickEntry = React.lazy(() => import('./components/pre-pick/directed-pick-entry'));
const PrePickConfirmation = React.lazy(() => import('./components/pre-pick/pre-pick-confirmation'));
const InputRoute = React.lazy(() => import('./components/pre-pick/input-route'));
const AddCart = React.lazy(() => import('./components/pre-pick/add-cart'));
const CartConfirmation = React.lazy(() => import('./components/pre-pick/cart-confirmation'));
const DoPicking = React.lazy(() => import('./components/execute-pick/do-picking'));
const PickingComplete = React.lazy(() => import('./components/post-pick/picking-complete'));

export const ROUTE_PATH = {
    INDEX: "/",
    HOME: "/home",
    ADD_CART_FOR_PICKING: "/add-cart",
    CART_CONFIRMATION: "/cart-confirmation",
    PRE_PICK_CONFIRMATION: "/pre-pick-confirmation",
    INPUT_ROUTE: "/input-route",
    DO_PICKING: "/do-picking",
    PICKING_COMPLETE: "/picking-complete"
};

ROUTE_PATH.WITH_DO_PICKING = {
    PICK_ITEM: `${ROUTE_PATH.DO_PICKING}/:itemId`,
    WORKFLOW_TRANSITION: `${ROUTE_PATH.DO_PICKING}/workflow-transition/:lastItemId/:nextItemId`,
    ADD_CART: `${ROUTE_PATH.DO_PICKING}/add-cart`,
    SCAN_CART_FOR_PICKING: `${ROUTE_PATH.DO_PICKING}/scan-cart-for-picking/:itemId/:wrongScan`,
    STAGE_CART: `${ROUTE_PATH.DO_PICKING}/stage-cart`,
    MISSING_ITEM: `${ROUTE_PATH.DO_PICKING}/missing-item/:itemId`,
    BARCODE_ENTRY: `${ROUTE_PATH.DO_PICKING}/barcode-entry/:itemId`,
    DAMAGED_MODAL: `${ROUTE_PATH.DO_PICKING}/damaged-modal`,
    PICKING_ISSUE: `${ROUTE_PATH.DO_PICKING}/picking-issue/:title/:description`,
    MIXED_CART_LOADING_ORDER:`${ROUTE_PATH.DO_PICKING}/mixed-cart-loading-order/:itemId`
};

function Routing(props) {

    props.history.listen((args) => {
        const oldPathName = props.location.pathname;
        const newPathName = args.pathname;
        if (oldPathName !== newPathName) {
            console.log(`Navigating from ${oldPathName} to ${newPathName}`);
            PUMAMetricHelper.publishCountToDolphinCSM(newPathName);
        }
    });

    // The sequencing matters in the routing
    return <AppError>
        <React.Suspense fallback={<Loader/>}>
            <Switch>
                <Route path={ROUTE_PATH.INPUT_ROUTE} component={InputRoute}/>
                <Route path={ROUTE_PATH.PRE_PICK_CONFIRMATION} component={PrePickConfirmation}/>
                <Route path={ROUTE_PATH.ADD_CART_FOR_PICKING} component={AddCart}/>
                <Route path={ROUTE_PATH.CART_CONFIRMATION} component={CartConfirmation}/>
                <Route path={ROUTE_PATH.DO_PICKING} component={DoPicking}/>
                <Route path={ROUTE_PATH.PICKING_COMPLETE} component={PickingComplete}/>

                {/* The last entry is the home page*/}
                <Route path={ROUTE_PATH.HOME} component={DirectedPickEntry}/>
                <Route path={ROUTE_PATH.INDEX} component={AppLauncher}/>
            </Switch>
        </React.Suspense>
    </AppError>;
}

export default withRouter(Routing);
